import React from 'react';
import { useParams } from 'react-router-dom';
import MRF from './MRF';

const MRFWrapper = (props) => {
    const { clientId, fileKey } = useParams();
    return <MRF {...props} clientId={clientId} fileKey={fileKey} />;
};

export default MRFWrapper;
