import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import MRFWrapper from './components/MRFWrapper';
import store from './redux/store';
import { Provider } from 'react-redux';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={<MRFWrapper />} />
          <Route path="/:clientId" element={<MRFWrapper />} />
          <Route path="*" element={<Navigate to='/' />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
