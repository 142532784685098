import { getFileList } from './services';
import { stateActions } from './reducers';

const apiRequest = () => ({ type: stateActions.loading });
const fetchSuccess = (data) => ({ type: stateActions.success, payload: data });
const apiFailure = (error) => ({ type: stateActions.error, payload: error });

export const getMRF = (clientId) => async (dispatch) => {
    dispatch(apiRequest());
    try {
        const data = await getFileList(clientId);
        if (data.length) {
            dispatch(fetchSuccess(data))
        } else {
            dispatch(apiFailure('Invalid client ID or no records found.'));
        }

    } catch (error) {
        dispatch(apiFailure(error.response?.data || 'Error fetching file list.'));
    }
};