const initialState = {
    files: [],
    loading: false,
    error: null
};

export const stateActions = {
    'loading': 'LOADING_FILES',
    'success': 'FILES_SUCCESS',
    'error': 'GENERAL_ERROR',
}

const filesReducer = (state = initialState, action) => {
    switch (action.type) {
        case stateActions.loading:
            return { ...state, loading: true };
        case stateActions.success:
            return { ...state, files: action.payload, loading: false };
        case stateActions.error:
            return { ...state, error: action.payload, loading: false };
        default:
            return state;
    }
};

export default filesReducer;
